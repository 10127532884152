//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
export default {
  props: {
    popType: String,
    popLabel: String,
  },
  name: "Poptree",
  data() {
    return {
      fieldVal: "",
      label: "部门",
      rule: [{ required: true, message: "" }],
      cascaderValue: "",
      valPicker: false,
      pickTitle: "请选择",
      colobj: {
        value: "countryCode",
        name: "countryName",
      },
      options: [],
    };
  },
  created() {
    this.initPop();
    this.fieldVal = this.defaultVal;
  },

  methods: {
    initPop() {
      this.label = this.popLabel;
    },

    showList(value = "") {
      let requestData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {},
        payload:
          '{"hhrDeptCode":"","includedPosn":"N","includedPosnAndPerson":"N","tenantId":0,"treeType":"init"}',
      };
      if (value != "") {
        requestData = {
          pathVariableMap: {
            tenantId: "0",
          },
          headerParamMap: {},
          requestParamMap: {},
          payload:
            '{"hhrDeptCode":"' +
            value.value +
            '","includedPosn":"N","includedPosnAndPerson":"N","tenantId":0,"treeType":"childNodes","parentId":"D-' +
            value.value +
            '"}',
        };
      } else if (this.options.length > 0) {
        this.valPicker = true;
        return;
      }
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });
      this.axios
        .post("/boogooForm/queryOrgTreeData", requestData)
        .then((res) => {
          Toast.clear();
          this.valPicker = true;
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);

              console.log(resultData);
              if (resultData.length > 0) {
                this.buildTree(resultData, value);
              }

              //  this.options=resultData;
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    buildTree(obj, value) {
      let childNodes = [];
      for (var i = 0; i < obj.length; i++) {
        var nodeItem = {
          text: obj[i].hhrDeptName,
          value: obj[i].hhrDeptCode,
          data: obj[i],
        };
        if (obj[i].children) {
          nodeItem.children = obj[i].children;
        }
        childNodes.push(nodeItem);
      }

      function fn(obj) {
        for (var i = 0; i < obj.length; i++) {
          if (obj[i].value == value.value) {
            obj[i].children = childNodes;
            break;
          }
          if (obj[i].children && obj[i].children.length > 0) {
            fn(obj[i].children);
          }
        }
      }
      if (value) {
        fn(this.options);
      } else {
        this.options = childNodes;
      }

      console.log("this.options", this.options);
    },
    onChange(value) {
      console.log("onChange", value);

      if (value.selectedOptions[value.tabIndex].children&&value.selectedOptions[value.tabIndex].children.length == 0) {
        this.showList(value);
      }

      // if (value === this.options[0].value) {
      //   setTimeout(() => {
      //     this.options[0].children = [
      //       { text: "杭州市", value: "330100" },
      //       { text: "宁波市", value: "330200" },
      //     ];
      //   }, 500);
      // }
    },
    onFinish({ selectedOptions }) {
      console.log("fis");
      this.valPicker = false;
      this.fieldVal = selectedOptions.map((option) => option.text).join("/");

      this.$emit("popconfirm", selectedOptions[selectedOptions.length - 1]);
    },
    pcikConfirm(value) {
      console.log(value);
      this.valPicker = false;
      this.fieldVal = value.text;
      let key = this.popKey ? this.popKey : this.popType;
      let popData = {
        key: key,
        value: value,
      };
      this.$emit("popconfirm", popData);
    },
  },
  mounted() {},
};
